






































import {Component, Vue} from "vue-property-decorator";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PasswordResetCompletionRequestDTO from "@/dto/auth/PasswordResetCompletionRequestDTO";
import {namespace} from "vuex-class";
import PortalInput from "@/components/common/PortalInput.vue";
import { processError } from "@/utils/ComponentUtils";
import Notifications from "@/state/Notifications";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component<PasswordResetCompletion>({
	components: {PortalInput},
})
export default class PasswordResetCompletion extends Vue {

  private successful = false;

  private request = new PasswordResetCompletionRequestDTO(this.$route.params.resetCode);

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @AppModule.State
  private isMobile!: boolean

  @Auth.Action
  private resetPassword!: (request: PasswordResetCompletionRequestDTO) => Promise<any>;

  reset() {
    this.$validator.validateAll().then((isValid) => {
      if (isValid) {
        this.startLoading();
        this.resetPassword(this.request).then(
          (data) => {
            Notifications.success(this.$t('auth.successful_reset') as string);
            this.successful = true;
            this.stopLoading();
            this.afterLogin();
          },
          (error) => {
            processError(error, this)
            this.stopLoading()
          }
        );
      }
    });
  }

  afterLogin(): void {
    this.$router.push("/");
  }

}
